// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  content: null,
  vacancies: null,
  vacancy: null
};

const getters = {};

const actions = {
  async getContent({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`vacancies/content/get`);

      if (response.data && response.data.data) {
        commit('setContent', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createContent({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`vacancies/content/store`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updateContent({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`vacancies/content/update`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getVacancies({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`vacancies/index`);

      if (response.data && response.data.data) {
        commit('setVacancies', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getVacancy({ commit } , id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`vacancies/find?id=` + id);

      if (response.data && response.data.data) {
        commit('setVacancy', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createVacancy({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`vacancies/store`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updateVacancy({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`vacancies/update`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteVacancy({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`vacancies/delete`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setContent(state, data) {
    state.content = data;
  },
  setVacancies(state, data) {
    state.vacancies = data;
  },
  setVacancy(state, data) {
    state.vacancy = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
