import { createStore } from 'vuex'
import main from "./modules/main"
import auth from "./modules/auth"
import global from './modules/global'
import news from './modules/news'
import profile from './modules/profile'
import contacts from "./modules/contacts"
import about from "./modules/about"
import subsidiary from "./modules/subsidiary"
import services from "./modules/services"
import consumer from "./modules/consumer"
import vacancies from "./modules/vacancies"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    main,
    auth,
    global,
    news,
    profile,
    contacts,
    about,
    subsidiary,
    services,
    consumer,
    vacancies
  }
})
