import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + 'api/',
  headers: {
    'Access-Control-Allow-Origin': "*"
  }
});

axiosInstance.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("authAdminToken");
  if (token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
