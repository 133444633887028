export async function loadLayoutMiddleware(to, from, next) {
  try {
      const layout = to.meta.layout || 'AppLayoutDefault'; // Установка макета по умолчанию
      const layoutComponent = await import(`@/layouts/${layout}.vue`);
      to.meta.layoutComponent = layoutComponent.default;
      next();
  } catch (e) {
      console.error('Error occurred in processing of layouts: ', e);
      const layoutComponent = await import('@/layouts/AppLayoutDefault.vue');
      to.meta.layoutComponent = layoutComponent.default;
      next();
  }
}