<template>
  <main-loader v-if="isLoading" />
  <component v-else class="app__height" :is="layoutComponent">
    <router-view/>
  </component>
</template>

<script setup>
import MainLoader from "@/components/global/MainLoader.vue";
import { computed, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const layoutComponent = computed(() => {
  return route.meta.layoutComponent || import('@/layouts/AppLayoutDefault.vue');
});

const isLoading = ref(true);

onMounted(async () => {
  setTimeout(() => {
    isLoading.value = false;
  }, 1000)
});
</script>

<style>
.app__height {
  min-height: 100vh;
}
</style>
