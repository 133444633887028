// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';

const state = {
  tabs: null,
  tab: null,
  tab_contents: null,
  tab_content: null,
  files: null,
};

const getters = {};

const actions = {
  async getTabs({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`consumers/get_tabs`);

      if (response.data && response.data.data) {
        commit('setTabs', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getTab({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`consumers/find_tab?id=` + id);

      if (response.data && response.data.data) {
        commit('setTab', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createTab({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/store_tab`, body);

      // if (response.data && response.data.data) {
      //   commit('setUsers', response.data.data);
      // }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updateTab({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/update_tab`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteTab({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/delete_tab`, {
        id: id
      });

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getTabContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`consumers/get_tab_contents?tab_id=` + id);

      if (response.data && response.data.data) {
        commit('setTabContent', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createTabContent({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/store_tab_content`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async updateTabContent({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/update_tab_content`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteTabContent({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/delete_tab_content`, {
        id: id
      });

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getTabContentById({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`consumers/find_tab_content?id=` + id);

      if (response.data && response.data.data) {
        commit('setTabContentById', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async AddFile({ commit }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/store_tab_content_file`, body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getFiles({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get(`consumers/get_tab_content_files?id=` + id);

      if (response.data && response.data.data) {
        commit('setTabFiles', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deleteFile({ commit }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post(`consumers/delete_tab_content_file`, {
        id: id
      });

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setTabs(state, data) {
    state.tabs = data;
  },
  setTab(state, data) {
    state.tab = data;
  },
  setTabContent(state, data) {
    state.tab_contents = data;
  },
  setTabContentById(state, data) {
    state.tab_content = data;
  },
  setTabFiles(state, data) {
    state.files = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
