// Импортируйте axiosInstance, если он вам нужен
import axiosInstance from '../../helpers/instance';
import router from '@/router/index.js';

const state = {
  token: sessionStorage.getItem('authAdminToken') || null
};

const getters = {
  isAuthenticated: state => !!state.token
};

const actions = {
  async login({ commit }, body) {
    try {
      const response = await axiosInstance.post('/auth/login', body);

      if (response && response.data) {
        if(response.data.data && response.data.data.access_token){
          commit('setToken', response.data.data.access_token);
          window.location.replace('/')
        }
      }

      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  logout({ commit }) {
    commit('clearToken');
    window.location.replace('/login')
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    sessionStorage.setItem('authAdminToken', token);
  },
  clearToken(state) {
    state.token = null;
    sessionStorage.removeItem('authAdminToken');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
