import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Breadcrumbs from './components/global/Breadcrumbs.vue';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import "./assets/styles/index.scss"
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from 'quill-image-uploader';
import CKEditor from '@ckeditor/ckeditor5-vue';
// import CKEditor from '@mayasabha/ckeditor4-vue3';
const app = createApp(App);
app.component('Breadcrumbs', Breadcrumbs);
app.component('ImageUploader', ImageUploader)
app.component('QuillEditor', QuillEditor)
app.use(store);
app.use(router);
app.use(VueSplide);
app.use(CKEditor)
app.mount('#app');
